import * as React from 'react';
import { styled } from '@mui/system';
import { alpha } from '@mui/material';
import theme from '../../theme';

import { StaticImage } from 'gatsby-plugin-image';

// @material-ui/core components
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import Stack from '@mui/material/Stack';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';

// import separator from '../images/separatorWhite.png';

const Section = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	backgroundColor: alpha(theme.palette.background.paper, 1),
	position: 'relative',
	textAlign: 'center',
	scrollSnapType: 'y mandatory',
	scrollBehavior: 'smooth',
}));

const FrontContent = styled(Box)({
	marginTop: 'auto',
	marginBottom: '50px',
	margin: 'auto',
	color: theme.palette.background.default,
});

const ScrollAnchor = styled(Box)({
	position: 'absolute',
	top: '-84px',
	scrollSnapAlign: 'start',
	scrollSnapStop: 'always',
});

function AboutSection() {
	return (
		<Section className='section' px={8}>
			<ScrollAnchor id='noob' />
			<FrontContent px={8} maxWidth='lg' className='pItem pFnt'>
				<Box className='container' maxWidth='lg' py={4}>
					{/* <Grid container justify='center'>
						<Grid item xs={12} sm={10}> */}
					<Typography
						my={2}
						component='h5'
						variant='h5'
						color='primary'
						data-scroll-reveal='enter bottom move 10px over 1s after 0.2s'
					>
						Two words - one philosophy
					</Typography>
					<Typography
						my={2}
						component='h5'
						variant='h5'
						color={theme.palette.background.default}
						data-scroll-reveal='enter top move 10px over 1s after 0.2s'
					>
						<span>Make Better</span>
					</Typography>
					<Typography
						variant='body1'
						data-scroll-reveal='enter bottom move 10px over 1s after 0.2s'
					>
						The Japanese call it Kaizen - 'Change for the Better'. An approach of
						continual improvement, small changes as often as possible.
					</Typography>
					<Typography
						variant='body1'
						data-scroll-reveal='enter bottom move 10px over 1s after 0.2s'
					>
						If we, as part of an organisation, understand our team and our individual
						actions required to achieve a common goal, we feel a sense of purpose in
						our work.&nbsp; Add to this the opportunity to use our skills to
						continually improve this work, with recognition from our peers and
						managers, this sense of worth and belonging thrive.
					</Typography>
					<h4>Is your organisation looking to "Make Better"</h4>
					<Typography
						variant='body1'
						data-scroll-reveal='enter bottom move 10px over 1s after 0.2s'
					>
						ALEiGN provides a tool that connects people and builds relationships to
						find the right people, to do the right jobs, to deliver great products and
						service while growing a satisfying, encouraging, enjoyable workplace
						culture.... ALEiGN-ment.
					</Typography>
					<Typography
						variant='body1'
						data-scroll-reveal='enter bottom move 10px over 1s after 0.2s'
					>
						A good team is comprised of round pegs and square holes. The project
						visionary may not know their CMS from their MVC, a developer may not quite
						see the beauty in the white space, the designer may not understand the
						complexity of mobile first and html email code and the fact that an end
						user just wants to get to an answer to their problem quickly, for it to be
						a nice experience and not make them feel stupid.
					</Typography>
					<Typography my={2} component='h4' variant='h4' color='primary'>
						We all have things we’re good at and things we just don't like doing.
						That’s fine, it's a pretty fair bet that there is a Ying for your Yang...
					</Typography>
					<Typography
						my={2}
						component='h4'
						variant='h4'
						color='primary'
						data-scroll-reveal='enter bottom move 10px over 1s after 0.2s'
					>
						It's a matter of finding your team of Misfits and fitting them together.
					</Typography>
					<Typography
						my={2}
						component='h5'
						variant='h5'
						color='primary'
						data-scroll-reveal='enter bottom move 10px over 1s after 0.4s'
					>
						Do you have a team of talented misfits you believe can build a great
						culture?
					</Typography>
					<Typography
						my={2}
						component='h5'
						variant='h5'
						color='primary'
						data-scroll-reveal='enter bottom move 10px over 1s after 0.2s'
					>
						Does your organisations value your people and equally, care for and value
						your customers? If so...
					</Typography>

					<Stack
						direction='row'
						justifyContent='center'
						alignItems='center'
						spacing={6}
						mt={4}
					>
						<Button
							size='lg'
							href='#'
							target='_blank'
							rel='noopener noreferrer'
							variant='aleign'
						>
							I get it, <br />
							show me the detail
						</Button>
						<Button
							size='lg'
							href=' '
							target='_blank'
							rel='noopener noreferrer'
							variant='aleign'
						>
							Interesting, <br />
							Tell me more
						</Button>
					</Stack>
					{/* </Grid>
					</Grid> */}
				</Box>
			</FrontContent>
		</Section>
	);
}

export default AboutSection;
